import React from 'react';
import { useNavigate } from 'react-router-dom';
import Edit from '../../../assets/icons/pen.svg';
import TooltipComponent from '../bootstrap-component/tooltip-component';

interface EditTranslationIconProps {
  translationKey: string;
  translationType?: string;
}

const EditTranslationIcon: React.FC<EditTranslationIconProps> = ({ translationKey, translationType }) => {
  const navigate = useNavigate();
  let translationTooltip: string;

  switch (translationType) {
    case 'text':
      translationTooltip = 'Edit text';
      break;
    case 'tooltip':
      translationTooltip = 'Edit tooltip';
      break;
    case 'placeholder':
      translationTooltip = 'Edit placeholder';
      break;
    case 'news':
      translationTooltip = 'Edit News';
      break;
    default:
      translationTooltip = 'Edit text'; 
  }

  return (
    <TooltipComponent title={translationTooltip}>
      <img
        src={Edit}
        alt="Edit"
        className="editIconSize cursor-pointer"
        onClick={() => {
          navigate('/app/manageTranslation', { state: { passedTranslationKey: translationKey } });
        }}
      />
    </TooltipComponent>
  );
};

export default EditTranslationIcon;