import { useTranslation } from 'react-i18next';
import { AskGPTMessages, convertMarkdownToHtml, convertToThousands, getTranslatedText, hideModal } from '../../../../util/util';
import Email from '../../../../assets/icons/email.svg';
import Like from '../../../../assets/icons/like.svg';
import Dislike from '../../../../assets/icons/dislike.svg';
import Share from '../../../../assets/icons/share.svg';
import LikeFilled from '../../../../assets/icons/like-filled.svg';
import DislikeFilled from '../../../../assets/icons/dislike-filled.svg';
import Tab from '../../../../assets/icons/tab.svg';
import Comment from '../../../../assets/icons/comment.svg';
import Editor, { BtnBold, BtnItalic, EditorProvider, Toolbar } from 'react-simple-wysiwyg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Card from '../../../common/card/card';
import { useGetUserProfileMutation, useSubmitSurveyResponseMutation } from '../../../../api-integration/secure/secure';
import { fullPageLoader, updateAlertMessage, updateUser, updateTileInfo } from "../../../../api-integration/commonSlice";
import TooltipComponent from '../../../common/bootstrap-component/tooltip-component';
import { useCommentPromptMutation, useGeneratePdfMutation, useRatePromptMutation, useSendAIResponseEmailMutation } from '../../../../api-integration/public/common';
import SendCommentModal from '../../../common/modal/send-comment';
import { Modal } from "bootstrap";
import SendEmailModal from "../../../common/modal/send-email";
import StarRating from '../../star-rating/star-rating';
import StarCommentModal from '../../modals/star-comment-modal';
import usePageTranslation from '../../../../hooks/usePageTranslation';
import EditTranslationIcon from '../../../common/edit-translation-icon/EditTranslationIcon';
import { use } from 'i18next';
import SocialShare from '../../../common/modal/social-share';
import SocialShareIcons from '../../../common/modal/social-share-icons';

const AiResponse = (props: any) => {
  const { t } = useTranslation();
  const PageTranslation = usePageTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const { protocol, host } = window.location;
  const [html, setHtml] = useState<any>();
  const [totalCCUsed, setTotalCCUsed] = useState('0');
  const [averageCCUsed, setAverageCCUsed] = useState('0');
  const [promptId, setPromptId] = useState(0);
  //const [showSurveySuccessMsg, setShowSurveySuccessMsg] = useState(false);
  const [surveySuccessMsg, setSurveySuccessMsg] = useState('');
  const sendCommentModalId = "sendCommentModal";
  const sendEmailModalId = "sendEmailModal";
  const emailPattern = /\S+@\S+\.\S+/;
  const starRatingId = "starRatingId";

  const [userEmail, setUserEmail] = useState('');
  const [thumbsUpIcon, setThumbsUpIcon] = useState(Like);
  const [thumbsDownIcon, setThumbsDownIcon] = useState(Dislike);
  const [isIconDisabled, setIsIconDisabled] = useState(true);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [userRating, setUserRating] = useState('0');
  const [userRatingDown, setUserRatingDown] = useState('1');
  const [isShareIconVisible, setIsShareIconVisible] = useState(false);
  const [userComment, setUserComment] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [likeDislikeEvent, setLikeDislikeEvent] = useState('');
  //const [showResponseSection, setShowResponseSection] = useState(true);
  const [localUserFirstPromptId, setLocalUserFirstPromptId] = useState(0);

  const [ratings,setRatings] = useState<number>(0);    // State for default rating when the screen appears.
  const maxRating = 7;  // Variable to set maximum rating.
  const [tempRating, setTempRating] = useState<number>(0); // This state will be used to send the temporary stars to the modal when they are clicked.

  const [randomMsg, setRandomMsg] = useState(t(AskGPTMessages[0]));
  let myInterval: any;
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
  const { user, TranslationEditFlag} = useSelector((state: any) => state.commonSlice);
  const [isHidden, setIsHidden] = useState(props?.isLibraryTypeChanged);
      
  const [RateGPTResponse, { data: rateResponse, isLoading: isRateLoading, isSuccess: isRateSuccess, isError: isRateError, error: rateError }] = useRatePromptMutation()
  const [contentHeight, setContentHeight] = useState<number | string>(0);

  const [getUserDetailsAPI, { data: userInfo, isLoading: isUserDetailLoding, isSuccess: isUserDetailSuccess, isError  }] =
  useGetUserProfileMutation();

  const [submitSurveyResponseAPI, { data: surveyResponse, isLoading: isSurveyLoading, isSuccess: isSurveySuccess, isError: isSurveyError, error: surveyError }] =
  useSubmitSurveyResponseMutation();

  const [generatePdfAPI, { data: pdfResponse, isLoading: isPdfLoading, isSuccess: isPdfSuccess, isError: isPdfError, error: pdfError }] = 
  useGeneratePdfMutation();

  const [SendAIResponseEmail, { data: sendAIResponseEmailResponse, isLoading: isSendLoading, isSuccess: isSendSuccess, isError: isSendError, error: err }] =
  useSendAIResponseEmailMutation();
  
  const [commentAPI, { data: commentResposne, isLoading: isCommentLoading, isSuccess: isCommentSuccess, isError: isCommentError, error: ommentError }] =
  useCommentPromptMutation();

  const SetMessages = () => {
    setRandomMsg(t(AskGPTMessages[Math.floor(Math.random() * 2) + 1]))
  }

  const onChange = (e: any) => {
    setHtml(e.target.value)
  }
  const rateGptBlue = async (rateVal: number, comment: string) => {
    // dispatch(fullPageLoader(true));
    // setUserRating(rateVal)
    // setLikeDislikeEvent('like');
    RateGPTResponse({ 
      userPromptId: props?.askGPTResponse?.userPromptId, 
      userRating: rateVal,
      userComment: comment
    });
  }

  const rateDownGptBlue = async (rateVal: string) => {
    // dispatch(fullPageLoader(true));
    setUserRatingDown(rateVal)
    setLikeDislikeEvent('dislike');
    RateGPTResponse({ 
      userPromptId: props?.askGPTResponse?.userPromptId, 
      userRating: rateVal })
  }

  const sendEmailDirectly = () => {
    dispatch(fullPageLoader(true));
    let user = JSON.parse(localStorage.getItem('user') as string) || {};
    const aiAdvice = props?.askGPTResponse?.aiAdvice ? props?.askGPTResponse?.aiAdvice : "";
    sendAIResponseEmail({ 
      GPTBlueUserPrompt: props?.prompt?.PROMPTNAME, 
      GPTBlueUserEmail: user?.userEmail, 
      userPromptId: props?.askGPTResponse?.userPromptId, 
      GPTBlueResponse: convertMarkdownToHtml(aiAdvice, 'stdText') 
    })
  }
  const sendEmail = () => {
    if (userEmail.length > 0 && emailPattern.test(userEmail)) {
      setEmailError(false);
      dispatch(fullPageLoader(true));
      sendAIResponseEmail({ 
        GPTBlueUserPrompt: props?.prompt?.PROMPTNAME, 
        GPTBlueUserEmail: userEmail, 
        userPromptId: props?.askGPTResponse?.userPromptId, 
        GPTBlueResponse: props?.askGPTResponse?.aiAdvice 
      })
      .then(() => {
        // This function hides the modal
        hideModal(sendEmailModalId);
      })
    } else {
      setEmailError(true);
    }
  }

  const sendComment = () => {
    if (userComment.length > 0) {
      setCommentError(false);
      dispatch(fullPageLoader(true));
      commentAPI({
        "promptResponseId": props?.askGPTResponse?.userPromptId,
        "userComment": userComment
      })
      .then(() => {
        // This function hides the modal.
        hideModal(sendCommentModalId);
      })
    } else {
      setCommentError(true);
    }
  }

  const generatePdf = () => {
    dispatch(fullPageLoader(true));
    const aiAdvice = props?.askGPTResponse?.aiAdvice? props?.askGPTResponse?.aiAdvice : ""
    generatePdfAPI({ 
      "promptResponseId": props?.askGPTResponse?.userPromptId, 
      promptResponse: convertMarkdownToHtml(aiAdvice, 'stdText') 
    })
  }

  const emailClicked = () => {
    isLoggedIn ? sendEmailDirectly() : setShowEmailModal(true)
  }

  useEffect(() => {
    if(isSurveySuccess) {
      dispatch(fullPageLoader(false));
      props.setShowResponseSection(false);
      props.setShowSurveySuccessMsg(true);
      setSurveySuccessMsg(surveyResponse?.message);
    }
    else if (isSurveyError || surveyError) {
      dispatch(fullPageLoader(false));
      dispatch(updateAlertMessage({ status: 'error', message: surveyResponse?.message }))
    }
  }, [isSurveySuccess, isSurveyError, surveyError])

  useEffect(() => {
    setIsHidden(props?.isLibraryTypeChanged);
  }, [props?.isLibraryTypeChanged]);

  useEffect(() => {
    const shouldDisableIcon = (!props?.askGPTResponse || props?.askGPTResponse?.success === false || props?.loading || !params.id || !props.showResponseSection || props?.isIterative);
    setIsIconDisabled(shouldDisableIcon);
    setRatings(0);
  }, [props?.askGPTResponse, props?.loading, params.id, props.showResponseSection, props?.isIterative]);

  const handleFinishClick = () => {
    setIsIconDisabled(false);
    if (props?.isIterative) {
      props?.setIsIterativeFinished(true);
    }
  };

  const sendAIResponseEmail = async (data: any) => {
    dispatch(fullPageLoader(true));
    SendAIResponseEmail(data)
  }

useEffect(() => {
    if (sendAIResponseEmailResponse?.success == true) {
      dispatch(updateAlertMessage({ status: 'success', message: sendAIResponseEmailResponse?.message }))
      dispatch(fullPageLoader(false));
      setShowEmailModal(false)
    }
    else if ((sendAIResponseEmailResponse?.success == false)) {
      dispatch(updateAlertMessage({ status: 'error', message: sendAIResponseEmailResponse?.message }))
      dispatch(fullPageLoader(false));
      setShowEmailModal(false)
    }
  }, [isSendSuccess, isSendError])

  useEffect(() => {
    if(props?.prompt?.ALLOWSOCIALSHARE){
      setIsShareIconVisible(props?.prompt?.ALLOWSOCIALSHARE === 1 ? true : false);
    }
  }, [props?.prompt]);
  

  // useEffect(() => {
  //   setTimeout(() => {
  //     const aiResponseEle = document.getElementById('io_promptResult')!;
  //     setContentHeight(props?.askGptResponse?.aiAdvice ? aiResponseEle?.offsetHeight : '300');
  //   }, 100);
  // }, [props?.askGptResponse]);

  useEffect(() => {
    if (props?.askGPTResponse || props?.refineResults) {
      dispatch(fullPageLoader(false));
    }
    if(props?.refineResults?.aiAdvice && props.userPromptAction == 'refineResult') {
      // When user click on refine result button
      setTotalCCUsed('0');
      getUserDetailsAPI({});
      setHtml(convertMarkdownToHtml(props?.refineResults?.aiAdvice, 'stdText'));
      setTotalCCUsed(convertToThousands(props?.refineResults?.totalUsedCC));
      setPromptId(props?.prompt?.GPTBLUEPROMPTID);
    }
    else if (props?.askGPTResponse?.aiAdvice)  {
      // When user click on AskGPT button
      setTotalCCUsed('0');
      getUserDetailsAPI({});
      setLocalUserFirstPromptId(props?.askGPTResponse?.userPromptId);
      setHtml(convertMarkdownToHtml(props?.askGPTResponse?.aiAdvice, 'stdText'));
      setTotalCCUsed(convertToThousands(props?.askGPTResponse?.totalUsedCC));
      setPromptId(props?.prompt?.GPTBLUEPROMPTID);
    }
  }, [props?.askGPTResponse?.aiAdvice, props?.refineResults?.aiAdvice])

  useEffect(() => {
    if (isUserDetailSuccess) {
      dispatch(fullPageLoader(false));
      //if(userInfo?.user?.accountType && userInfo?.user?.accountType == 'user') {
      if(isLoggedIn) {
        let user = JSON.parse(localStorage.getItem('user') as string);
        user.totalCCPoints = userInfo?.user?.totalCCPoints;
        dispatch(updateUser(user));
        localStorage.setItem('user', JSON.stringify(user));
      }
    }
  }, [isUserDetailSuccess, userInfo])

  useEffect(() => {
    if (rateResponse?.success == true) {
      dispatch(updateAlertMessage({ status: 'success', message: rateResponse?.message }))
      dispatch(fullPageLoader(false));
      if (likeDislikeEvent == 'like') {
        if (userRating == "1") {
          setThumbsUpIcon(LikeFilled)
          if (thumbsDownIcon == DislikeFilled) {
            setThumbsDownIcon(Dislike)
            setUserRatingDown("1")
          }
        }
        else {
          setThumbsUpIcon(Like)
        }
        props?.onRatingPrompt()
      }
      if (likeDislikeEvent == 'dislike') {
        if (userRatingDown == '1') {
          setThumbsDownIcon(Dislike)
        } else {
          setThumbsDownIcon(DislikeFilled)
          if (thumbsUpIcon == LikeFilled) {
            setThumbsUpIcon(Like)
            setUserRating("0")
          }
        }
        props?.onRatingPrompt()
      }
    }
    else if ((rateResponse?.success == false)) {
      dispatch(updateAlertMessage({ status: 'error', message: rateResponse?.message }))
      dispatch(fullPageLoader(false));
    }

  }, [isRateSuccess, isRateError])

  useEffect(() => {
    if (commentResposne?.success == true) {
      dispatch(updateAlertMessage({ status: 'success', message: commentResposne?.message }))
      dispatch(fullPageLoader(false));
      props?.onRatingPrompt()
    }
    else if ((commentResposne?.success == false)) {
      dispatch(updateAlertMessage({ status: 'error', message: commentResposne?.message }))
      dispatch(fullPageLoader(false));
    }
  }, [isCommentSuccess, isCommentError])


  useEffect(() => {
    if (pdfResponse?.success == true) {
      dispatch(updateAlertMessage({ status: 'success', message: pdfResponse?.message }))
      dispatch(fullPageLoader(false));
      // window.open(pdfResponse?.pdfURL, '_blank');
      var file_path = pdfResponse?.pdfURL;
      var a = document.createElement('A') as any;
      a.href = file_path;
      //  This will open up the pdf in new page. 
      a.target = '_blank';
      a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    else if ((pdfResponse?.success == false)) {
      dispatch(updateAlertMessage({ status: 'error', message: pdfResponse?.message }))
      dispatch(fullPageLoader(false));
    }
    if (isPdfError) {
      dispatch(fullPageLoader(false));
      dispatch(updateAlertMessage({ status: 'error', message: t('message.generate_pdf_error') }))
    }

  }, [isPdfSuccess, isPdfError])

  useEffect(() => {
    myInterval = setInterval(SetMessages, 5000);
  }, [])
  
  useEffect(() => {
    setThumbsUpIcon(Like)
    setThumbsDownIcon(Dislike);
    setUserRating('0')
    setUserRatingDown('1')
  }, [props?.askGPTResponse])

  const handleSetRating = (rating: number, comment: string) => {
    setRatings(rating);
    rateGptBlue(rating-1,comment);
  }

  const handleTempRating = (rating: number) => {
    setTempRating(rating);
  }

  return (
    <>
      <Card id={props?.cardId}
        titleType={1} 
        cardHeightClass={props?.page == 'dtd' ? '' : 'h-100'} 
        title={props?.cardTitle}
        help={true} Feedback={props.pageType == 'embed' ? false : true} 
        logo={true} share={props.pageType == 'embed' ? false : true} 
        helpTitle={props?.cardHelpTitle}
        helpContent={props?.cardHelpContent}
      >
        <div className='d-flex flex-column align-content-between h-100'>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <div>
            <div className='fw-bold'>{t('text.total_cc_used.label')}: {totalCCUsed}</div>
          </div>
          {/* <div className={`text-end ${props?.page === 'dtd' ? 'd-none' : ''} ${(!props?.askGPTResponse || props?.askGPTResponse?.success === false || props?.loading || !params.id || !props.showResponseSection || props?.isIterative) ? 'disabled-item' : ''}`}> */}
          <div className={`text-end ${props?.page === 'dtd' ? 'd-none' : ''} ${isIconDisabled ? 'disabled-item' : ''}`}>
          <div className="d-inline-block text-center px-2">
              <div className="mb-1">{t('icons.rating_stars.label')}</div>
              <div className="d-inline-block">
                <StarRating
                  maxRating = {maxRating}
                  defaultRating = {ratings}
                  // onSetRating = {handleSetRating}
                  onTempRating = {handleTempRating}
                  messages = {
                    [
                      { color: 'var(--bs-gray)', tooltip: t('icons.rating_stars.tooltip.star_1') },
                      { color: 'var(--bs-primary)', tooltip: t('icons.rating_stars.tooltip.star_2') },
                      { color: 'var(--bs-primary)', tooltip: t('icons.rating_stars.tooltip.star_3') },
                      { color: 'var(--bs-primary)', tooltip: t('icons.rating_stars.tooltip.star_4') },
                      { color: 'var(--bs-primary)', tooltip: t('icons.rating_stars.tooltip.star_5') },
                      { color: 'var(--bs-primary)', tooltip: t('icons.rating_stars.tooltip.star_6') },
                      { color: 'var(--bs-secondary)', tooltip: t('icons.rating_stars.tooltip.star_7') }
                    ]
                  }
                />
              </div>
            </div>
            {/* Share Section */}
            {/* {isShareIconVisible &&
            <div className="d-inline-block text-center px-2">
                <div className="mb-1">
                    {getTranslatedText('share.text', PageTranslation)}
                    {TranslationEditFlag && (
                        <EditTranslationIcon translationKey="share.text" translationType="text" />
                    )}
                </div>
                <div className="d-inline-block">
                    <TooltipComponent title={getTranslatedText('ioResponseShare.tooltip', PageTranslation)} >
                        <img 
                            src={Share} 
                            className='h-1-5 cursor-pointer' 
                            data-bs-toggle="modal" 
                            data-bs-target="#socialShareResponseModalId"
                        />
                    </TooltipComponent>
                    {TranslationEditFlag && (
                        <EditTranslationIcon translationKey="ioResponseShare.tooltip" translationType="tooltip" />
                    )}
                </div>
            </div>
            } */}
            {/* <div className="d-inline-block text-center px-2">
              <div className="mb-1">{t('icons.like_prompt_response.label')}</div>
              <div className="d-inline-block"><TooltipComponent title={t('icons.like_prompt_response.tooltip')} ><img src={thumbsUpIcon} className='h-1-5 cursor-pointer' onClick={() => rateGptBlue(userRating == "0" ? "1" : "0")} /></TooltipComponent></div>
            </div>
            <div className="d-inline-block text-center px-2">
              <div className="mb-1">{t('icons.dislike_prompt_response.label')}</div>
              <div className="d-inline-block"><TooltipComponent title={t('icons.dislike_prompt_response.tooltip')} ><img src={thumbsDownIcon} className='h-1-5 cursor-pointer' onClick={() => rateDownGptBlue(userRatingDown == "1" ? "0" : "1")} /></TooltipComponent></div>
            </div> */}
            <div className="d-inline-block text-center px-2">
              <div className="mb-1">{t('icons.comment_prompt_response.label')}</div>
              <div className="d-inline-block">
                <TooltipComponent title={t('icons.comment_prompt_response.tooltip')} >
                  <img src={Comment} className='h-1-5 cursor-pointer' 
                    data-bs-toggle="modal"
                    data-bs-target={`#${sendCommentModalId}`}
                  />
                </TooltipComponent>
              </div>
            </div>
            <div className="d-inline-block text-center px-2">
              <div className="mb-1">{t('icons.email_prompt_response.label')}</div>
              <div className="d-inline-block">
                <TooltipComponent title={t('icons.email_prompt_response.tooltip')} >
                  <img src={Email} className='h-1-5 cursor-pointer' 
                    data-bs-toggle={isLoggedIn ? undefined : "modal"} 
                    data-bs-target={isLoggedIn ? undefined : `#${sendEmailModalId}`} 
                    onClick={isLoggedIn ? emailClicked : undefined}
                  />
                </TooltipComponent>
              </div>
            </div>
            <div className="d-inline-block text-center px-2">
              <div className="mb-1">{t('icons.pdf_prompt_response.label')}</div>
              <div className="d-inline-block"><TooltipComponent title={t('icons.pdf_prompt_response.tooltip')} ><img src={Tab} className='h-1-5 cursor-pointer' onClick={generatePdf} /></TooltipComponent></div>
            </div>
          </div>
        </div>
        { !(props?.refineLoading || props?.loading || isHidden) 
          && ( props?.askGPTResponse?.aiAdvice || props?.refineResults?.aiAdvice) 
          && props.showResponseSection
          && (
            ((props?.isIterative && !props?.isIterativeFinished) || props?.isSurveyPrompt) ?
              <>
                {props?.isSurveyPrompt &&
                <div>
                  {t('message.response_confirmation_msg', { firstname: user?.firstName})}
                </div>
                }
                <div id="AIAdviceResponse" className="m-2 text-break">
                  <EditorProvider>
                  <div className="overflow-auto scrollbar h-35vh" >
                    <Editor value={html} onChange={onChange}>
                      <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                      </Toolbar>
                    </Editor>
                    </div>
                  </EditorProvider>
                </div>
                <div className="mt-2 text-center">
                  <div className="d-inline-block">
                    <TooltipComponent title={props?.isSurveyPrompt ? t('buttons.re_review_response.tooltip') : t('prompt.btn.refine_result.tooltip')} >
                      <button type='button' 
                        disabled={props?.refineLoading} 
                        onClick={() =>{ 
                          props.refineResult(html, localUserFirstPromptId);
                          props?.setUserPromptAction('refineResult');
                          props?.setShowResponseSection(true);
                          props?.setShowSurveySuccessMsg(false);
                        }} 
                        className={`btn btn-primary btn-md rounded-pill px-3 mt-3 ${props?.refineLoading ? 'disabled' : ''}`} 
                      >
                          {props?.isSurveyPrompt ? t('buttons.re_review_response.label') : t('prompt.btn.refine_result.label')}
                      </button>
                    </TooltipComponent>
                  </div>
                  
                  {props?.isSurveyPrompt 
                    ?
                    <div className="ms-2 d-inline-block">
                      <TooltipComponent title={t('buttons.respond.tooltip')}>
                        <button type='button' 
                          //disabled={props?.refineLoading} 
                          onClick={() => {
                            dispatch(fullPageLoader(true));
                            submitSurveyResponseAPI({ 
                              gptBluePromptId: promptId, 
                              surveyResponse: html 
                            })
                          }} 
                          className="btn btn-primary btn-md rounded-pill px-3 mt-3 "
                        >
                          {t('buttons.respond.label')}
                        </button>
                      </TooltipComponent>
                    </div>
                    :
                    <div className="ms-2 d-inline-block">
                      <TooltipComponent title={t('buttons.finish.tooltip')}>
                        <button type='button' 
                          onClick={handleFinishClick} 
                          className="btn btn-primary btn-md rounded-pill px-3 mt-3"
                        >
                          {t('buttons.finish.label')}
                        </button>
                      </TooltipComponent>
                    </div>
                  }
                </div>
              </>
              :
              <div className="p-2  overflow-auto scrollbar h-41vh" 
                dangerouslySetInnerHTML={{ __html: html }}
              ></div>
        )}
        {
          !(props?.loading || props.refineLoading) && !props?.askGPTResponse && !props?.isAskGPTError && !props?.refineResults?.aiAdvice &&
          <div className="py-1">
            {props?.page === 'dtd' ? t('prompt.click_AskGPTBlue') :
            (props?.page === 'io' && props?.isSurveyPrompt) ? t('prompt.survey_prompt_review_msg') :
            t('prompt.click_AskGPTBlue_msg2')}
          </div>
        }
        {
          props?.isAskGPTError &&
          <div>
            <span className='fw-bold'>{t('message.failed')}</span>
            {t('message.error_try_again')}
          </div>
        }
        {
          (props?.loading || props?.refineLoading) && <div className='ps-2 py-1'>
            <span id="processingMsg" className="textDarkGreen">
              {randomMsg}
              <div className="spinner-border text-success" role="status"><span className="visually-hidden">Loading…</span></div>
            </span>
          </div>
        }
        {isSurveyLoading && 
          <div className='text-center'>
            <div className="spinner-border spinner-border-sm text-primary" role="status">
              <span className="visually-hidden">Loading…</span>
            </div>
          </div>
        }
        {props.showSurveySuccessMsg && 
          <div className="text-center mt-2">{surveySuccessMsg}</div>
        }
        {isShareIconVisible &&
          <div className={`mt-auto d-flex justify-content-center ${props?.page === 'dtd' ? 'd-none' : ''} ${isIconDisabled ? 'disabled-item' : ''}`}>
            <div className="mt-3">
              <SocialShareIcons
                  size={20}
                  shareObject = {"prompt"}
                  shareObjectId={props?.prompt?.GPTBLUEPROMPTID?.toString() || ""} 
                  contentShareTitle={props?.prompt?.PROMPTNAME || ""} 
                  contentShareDesc= {html && `${props?.prompt?.DEFAULTACTION} \n\n ${html.replace(/<\/?[^>]+(>|$)/g, "")}` || ""}
                  sharePromptAuthor = {props?.prompt?.OWNERFIRSTNAME + ' ' + props?.prompt?.OWNERLASTNAME || ""} 
                  contentShareUrl={`${protocol}//${host}/app/io/${props?.prompt?.URLCODE}` || ""} 
              />
            </div>
          </div>
        }
        </div>
      </Card>
      <SendEmailModal 
        id={sendEmailModalId}
        onSendEmail={sendEmail}
        emailError={emailError}
        setEmailError={setEmailError}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        emailPattern={emailPattern}
      />
      <SendCommentModal 
        id={sendCommentModalId} 
        onSendComment={sendComment}
        commentError={commentError} 
        setCommentError={setCommentError} 
        userComment={userComment} 
        setUserComment={setUserComment}  
      />
      <StarCommentModal 
        id = {starRatingId}
        maxRating = {maxRating}
        rating ={tempRating}
        handleRate={handleSetRating}
        messages = {
          [
            { color: 'var(--bs-gray)', tooltip: t('icons.rating_stars.tooltip.star_1') },
            { color: 'var(--bs-primary)', tooltip: t('icons.rating_stars.tooltip.star_2') },
            { color: 'var(--bs-primary)', tooltip: t('icons.rating_stars.tooltip.star_3') },
            { color: 'var(--bs-primary)', tooltip: t('icons.rating_stars.tooltip.star_4') },
            { color: 'var(--bs-primary)', tooltip: t('icons.rating_stars.tooltip.star_5') },
            { color: 'var(--bs-primary)', tooltip: t('icons.rating_stars.tooltip.star_6') },
            { color: 'var(--bs-secondary)', tooltip: t('icons.rating_stars.tooltip.star_7') }
          ]
        }
      />
      {/* <SocialShare 
          id = "socialShareResponseModalId"
          shareObject = {"prompt"}
          shareObjectId={props?.prompt?.GPTBLUEPROMPTID?.toString() || ""} 
          contentShareTitle={props?.prompt?.PROMPTNAME || ""} 
          contentShareDesc= {html && `${props?.prompt?.DEFAULTACTION} \n\n ${html.replace(/<\/?[^>]+(>|$)/g, "")}` || ""}
          sharePromptAuthor = {props?.prompt?.OWNERFIRSTNAME + ' ' + props?.prompt?.OWNERLASTNAME || ""} 
          contentShareUrl={`${protocol}//${host}/app/io/${props?.prompt?.URLCODE}` || ""} 
      /> */}
    </>
  )
}

export default AiResponse;