import React, { useEffect } from 'react';
//import { IconType } from 'react-icons';
//import { FaTwitter, FaEnvelope, FaWhatsapp, FaLinkedinIn } from 'react-icons/fa';
import TooltipComponent from '../bootstrap-component/tooltip-component';
//import { useTranslation } from 'react-i18next';
import { useGetUserProfileMutation, useGptBlueSocialShareMutation } from '../../../api-integration/secure/secure';
import { useDispatch, useSelector } from 'react-redux';
import { fullPageLoader,  updateUser } from '../../../api-integration/commonSlice';
import { initializeModal } from '../../../util/util';
import { getTranslatedText } from '../../../util/util';
import usePageTranslation from '../../../hooks/usePageTranslation';
import EditTranslationIcon from "../edit-translation-icon/EditTranslationIcon";
import SocialShareIcons from './social-share-icons';



interface SocialShareProps {
    id: string; // id of the modal
    shareObjectId: string; //id (e.g. tileCode or promptid) used in the API call to give callom coins to the user
    contentShareTitle: string; // Title of the content to be shared
    contentShareDesc: string; // Description of the content to be shared
    contentShareUrl: string; //Share URL used to form the content to be shared
    contentShareImageUrl?: string; // Not used
    contentShareParamId?: string; // Not used
    shareObject?: string; // 'tile' or 'prompt' to form the content
    sharePromptAuthor?: string; // in case of shareObject = 'prompt' >> Author of the prompt used in the content to be shared 
    //sharePromptImage?: string;
}

const SocialShare: React.FC<SocialShareProps> = ({ id, shareObjectId, contentShareTitle, contentShareDesc, contentShareUrl, contentShareImageUrl, contentShareParamId, shareObject, sharePromptAuthor }) => {
    //const { t } = useTranslation();
    const dispatch = useDispatch();
    const { TranslationEditFlag} = useSelector((state: any) => state.commonSlice);

    const PageTranslation = usePageTranslation();
    // const loggedInUser = JSON.parse(localStorage.getItem('user') as string);
    // const loggedInUserName = loggedInUser ? loggedInUser?.firstName + ' ' + loggedInUser?.lastName : '';
    
    // const [gptBlueSocialShareAPI, { data: socialShareData, isLoading: isSocialShareLoading, isSuccess: isSocialShareSuccess, isError: isSocialShareError, error: socialShareError}] = 
    //     useGptBlueSocialShareMutation();
    // const [getUserDetailsAPI, { data: userInfo, isLoading: isUserDetailLoding, isSuccess: isUserDetailSuccess, isError: isUserDetailError, error: userDetailError  }] =
    //     useGetUserProfileMutation();
    
    // const title = contentShareTitle;
    // const description = contentShareDesc;
    // const shareURL = contentShareUrl;
    // let content = '';
    // if(shareObject === 'prompt'){
    //     content = `"${contentShareTitle}" created by ${sharePromptAuthor}\n\n${description}\n${contentShareUrl}`;
    // }else{
    //     content = `${contentShareTitle}\n${description}\n${contentShareUrl}`;
    // }
    
    // const imageUrl = contentShareImageUrl;
    

    useEffect(() => {
        // Call the initializeModal function on mount/update
        initializeModal(id);
    
        return () => {
          // Call the initializeModal function on unmount/cleanup
          initializeModal(id);
        };
    }, [id]);

    // const shareToNetwork = (network: string) => {
    //     let url = '';
    
    //     switch (network) {
    //         case 'Twitter':
    //             url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(content)}`;
    //             dispatch(fullPageLoader(true));
    //             gptBlueSocialShareAPI({"socialnet": "Twitter", shareObject: shareObject ? shareObject : 'tile', "shareObjectId": shareObjectId });
    //             break;
    //         case 'Email':
    //             // let additionalContent = '<BR><BR><a href="https://bluecallom.com/#news" target="_blank"><img src="https://bluecallom.com/assets/images/signature-info.jpg"></a><BR>';
    //             // let tempDiv = document.createElement("div");
    //             // tempDiv.innerHTML = additionalContent;
    //             // // Get the plain text content
    //             // additionalContent = tempDiv.innerText || tempDiv.textContent || "";
                
    //             const emailBody = `Hello \n\nI just wanted to share with you \n${content}  \n\nKind Regards, \n${loggedInUserName}`;
    //             url = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(emailBody )}`;
    //             dispatch(fullPageLoader(true));
    //             gptBlueSocialShareAPI({"socialnet": "Email", shareObject: shareObject ? shareObject : 'tile', "shareObjectId": shareObjectId });
    //             break;
    //         case 'Facebook':
    //             url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(content)}`;
    //             dispatch(fullPageLoader(true));
    //             gptBlueSocialShareAPI({"socialnet": "Facebook", shareObject: shareObject ? shareObject : 'tile', "shareObjectId": shareObjectId });
    //             break;
    //         case 'LinkedIn':
    //             //url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(content)}`;
    //             url= `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareURL)}`;
    //             dispatch(fullPageLoader(true));
    //             gptBlueSocialShareAPI({"socialnet": "LinkedIn", shareObject: shareObject ? shareObject : 'tile', "shareObjectId": shareObjectId });
    //             break;
    //         case 'WhatsApp':
    //             url = `https://api.whatsapp.com/send?text=${encodeURIComponent(content)}`;
    //             dispatch(fullPageLoader(true));
    //             gptBlueSocialShareAPI({"socialnet": "WhatsApp", shareObject: shareObject ? shareObject : 'tile', "shareObjectId": shareObjectId });
    //             break;
    //         default:
    //             break;
    //     }
    //     window.open(url, '_blank');
    // };
    // useEffect(() => {
    //     if (isSocialShareSuccess && socialShareData) {
    //         dispatch(fullPageLoader(true));
    //         getUserDetailsAPI({accountType: 'user'});
    //     }
    //     if (isSocialShareSuccess || isSocialShareError || socialShareError) {
    //         dispatch(fullPageLoader(false));
    //     }
    // }, [isSocialShareSuccess, isSocialShareError, socialShareError]);

    // useEffect(() => {
    //     if (isUserDetailSuccess) {
    //         dispatch(fullPageLoader(false));
    //         let user = JSON.parse(localStorage.getItem('user') as string);
    //         if(userInfo?.user?.totalCCPoints){
    //             user.totalCCPoints = userInfo?.user?.totalCCPoints;
    //             dispatch(updateUser(user));
    //             localStorage.setItem('user', JSON.stringify(user));
    //         }
    //     }
    //     if (isUserDetailError || userDetailError) {
    //         dispatch(fullPageLoader(false));
    //         //dispatch(updateAlertMessage({ status: 'error', message: userInfo?.message }));
    //     }
    // }, [isUserDetailSuccess, isUserDetailError, userDetailError]);

    // color="#3b5998" -- Facebook's brand color
    // color="#1DA1F2" -- Twitter's brand color
    // color="#D44638" -- Gmail's brand color
    // color="#25D366" -- WhatsApp's brand color
    // color="#0077B5" -- LinkedIn's brand color

    //FaTwitter
    // const XTwitterIcon = () => (
    //     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="35" height="35">
    //       <path fill="white" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
    //     </svg>
    // );

    // const socialPlatforms = [
    //     { icon: FaLinkedinIn, label: getTranslatedText('linkedin.text',PageTranslation), onClick: () => shareToNetwork('LinkedIn'), color: '#0077B5', size: 38 },
    //     { icon: FaWhatsapp, label: getTranslatedText('whatsApp.text',PageTranslation), onClick: () => shareToNetwork('WhatsApp'), color: '#25D366', size: 38 },
    //     { icon: XTwitterIcon, label: getTranslatedText('twitter.text',PageTranslation), onClick: () => shareToNetwork('Twitter'), color: '#000000', size: 38 },
    //     { icon: FaEnvelope, label: getTranslatedText('email.text',PageTranslation), onClick: () => shareToNetwork('Email'), color: 'var(--bs-dark)', size: 38 },
    // ];

    return (
        <div className="modal fade modal-draggable" data-bs-backdrop="false" id={id} tabIndex={-1} aria-labelledby={`${id}Label`} aria-hidden="true">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={`${id}Label`}>
                            {getTranslatedText('socialShare.card.title',PageTranslation)}
                            {TranslationEditFlag && (
                                <EditTranslationIcon translationKey="socialShare.card.title" translationType='text'/>
                            )}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    
                    <div className="modal-body" >
                        <SocialShareIcons 
                            shareObjectId={shareObjectId}
                            contentShareTitle={contentShareTitle}
                            contentShareDesc={contentShareDesc}
                            contentShareUrl={contentShareUrl}
                            shareObject={shareObject}
                            sharePromptAuthor={sharePromptAuthor}
                        />
                        {/* <div className='d-md-flex justify-md-content-evenly'>
                        {socialPlatforms.map((platform, index) => {
                            const Icon = platform.icon;
                            return (
                                <div className='px-3 text-center' key={index} >
                                    <div className='cursor-pointer' onClick={platform.onClick} style={{ borderRadius: '50%', width: '60px', height: '60px', background: platform.color, display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Icon size={platform.size} color="white" />
                                    </div>
                                    <span className='cursor-pointer' onClick={platform.onClick}>{platform.label}</span>
                                    {TranslationEditFlag && (
                                        <EditTranslationIcon translationKey={platform.label} translationType='text'/>
                                    )}
                                </div>
                            );
                        })}
                        </div> */}
                        <div className="modal-footer mt-3">
                            <TooltipComponent title={getTranslatedText("close.tooltip", PageTranslation)}>
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                    {getTranslatedText("close.text", PageTranslation)}
                                </button>
                            </TooltipComponent>
                            {TranslationEditFlag && (
                                <>
                                    <EditTranslationIcon translationKey="close.text" translationType='text'/>
                                    <EditTranslationIcon translationKey="close.tooltip" translationType='tooltip' />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SocialShare;